import CardFeature from '~/components/Molecules/CardFeature/CardFeature.vue'

export default {
  props: {
    title: {
      type: Object,
    },
    body: {
      type: String,
    },
    leads: {
      type: Array,
    },
    button: {
      type: Object,
    },
  },

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  data() {
    return {
      cardFeature: CardFeature,

      swiperOptions: {
        slidesPerView: 1.1,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
        spaceBetween: 16,
        allowTouchMove: true,
        breakpoints: {
          320: {
            slidesPerView: 1.1,
            slidesPerColumn: 1,
          },
          768: {
            slidesPerView: 2,
            slidesPerColumn: 4,
          },
          1024: {
            slidesPerView: 4,
            slidesPerColumn: 4,
            allowTouchMove: false,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      fractionPagination: false,
    }
  },

  computed: {
    hasTitle() {
      return Boolean(this.title)
    },
    hasBody() {
      return Boolean(this.body)
    },
    hasLeads() {
      return Boolean(this.leads)
    },
    hasButton() {
      return Boolean(this.button)
    },
  },
}
