export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    icon: {
      type: Object,
    },

    title: {
      type: String,
    },

    body: {
      type: String,
    },

    cardFeatureClass: {
      type: String,
    },

    theme: {
      type: String,
      default: '',
      validator: prop => ['', 'dark'].includes(prop),
    },
  },

  computed: {
    noBody() {
      return this.body ? false : true
    },
  },
}
