import { render, staticRenderFns } from "./CardFeature.vue?vue&type=template&id=2bf5dfd0&scoped=true"
import script from "./CardFeature.js?vue&type=script&lang=js&external"
export * from "./CardFeature.js?vue&type=script&lang=js&external"
import style0 from "./CardFeature.scss?vue&type=style&index=0&id=2bf5dfd0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf5dfd0",
  null
  
)

export default component.exports